
 /* eslint-disable */
import { defineComponent, nextTick, onMounted, onUpdated, computed, watch } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import JwtService from "@/core/services/JwtService";
import { useRouter } from "vue-router"
 import store from "@/store";

export default defineComponent({
  name: "app",
  setup() {
    const router = useRouter()
    const store = useStore()

    const isAuthenticated = computed(() => {
      return store.getters.isUserAuthenticated;
    })

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });

    });

    // Check user login
    watch(() => isAuthenticated.value, (val) => {
      if(!val) {
        router.push({ name: 'login' })
      }
    }, { immediate: true })

    onUpdated(() => {
      /*setTimeout(() => {
        let x = document.querySelectorAll('.table')
        console.log(x)
        x[0].classList.add('gs-4')
      })*/
    })
  },
});
