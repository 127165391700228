const ID_TOKEN_KEY = "id_token" as string;
const USER_DATA = "usr_data" as string;
const USER_MENU = "usr_menu" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getUser = (): string | null => {
  return window.localStorage.getItem(USER_DATA)
};

export const getMenu = (): string | null => {
  return window.localStorage.getItem(USER_MENU);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveUser= (data: string): void => {
  window.localStorage.setItem(USER_DATA, JSON.stringify(data))
};

export const saveMenu= (data: string): void => {
  window.localStorage.setItem(USER_MENU, JSON.stringify(data))
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  try {
    window.localStorage.removeItem(USER_DATA);
    window.localStorage.removeItem(USER_MENU);
    window.localStorage.removeItem(ID_TOKEN_KEY);
  } catch(e) {
    console.error(e)
  }
};

export default { getToken, getUser, getMenu, saveToken, saveUser, saveMenu, destroyToken };
