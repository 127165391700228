import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/assets/plugins/global/plugins.bundle.css" 
import "@/assets/css/style.bundle.css"
import "@/assets/css/custom.css"
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import VueBarcode from '@chenfengyuan/vue-barcode';
//import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";

import "@/core/plugins/prismjs";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus, { size: 'large', zIndex: 3000 });
app.component(VueBarcode.name, VueBarcode);
app.config.warnHandler = () => null;

ApiService.init(app);
//initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
