import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Product {
  listVoucher: unknown;
  listBrand: unknown;
  listCategory: unknown;
  listVendor: unknown;
  listStore: unknown;
  listColor: unknown;
  listSize: unknown;
  listRawFolder: unknown;
  listProductFolder: unknown;
  listDesignCategory: unknown;
  listPaymentMethod: unknown;
}

@Module
export default class ProductModule extends VuexModule implements Product {
  // Getters
  get getListVoucher() {
    return this.listVoucher;
  }

  get getListBrand() {
    return this.listBrand;
  }

  get getListCategory() {
    return this.listCategory;
  }

  get getListVendor() {
    return this.listVendor;
  }

  get getListStore() {
    return this.listStore;
  }

  get getListColor() {
    return this.listColor;
  }

  get getListSize() {
    return this.listSize;
  }

  get getListRawFolder() {
    return this.listRawFolder;
  }  

  get getListProductFolder() {
    return this.listProductFolder;
  }

  get getListDesignCategory() {
    return this.listDesignCategory;
  }

  get getListPaymentMethod() {
    return this.listPaymentMethod;
  }

  get getListWarehouse() {
    return this.listWarehouse;
  }

  // Mutations
  @Mutation
  [Mutations.SET_LIST_VOUCHER](data) {
    this.listVoucher = data;
  }
  
  @Mutation
  [Mutations.SET_LIST_BRAND](data) {
    this.listBrand = data;
  }
  
  @Mutation
  [Mutations.SET_LIST_CATEGORY](data) {
    this.listCategory = data;
  }
  
  @Mutation
  [Mutations.SET_LIST_VENDOR](data) {
    this.listVendor = data;
  }
  
  @Mutation
  [Mutations.SET_LIST_STORE](data) {
    this.listStore = data;
  }

  @Mutation
  [Mutations.SET_LIST_COLOR](data) {
    this.listColor = data;
  }

  @Mutation
  [Mutations.SET_LIST_SIZE](data) {
    this.listSize = data;
  }

  @Mutation
  [Mutations.SET_LIST_RAW_FOLDER](data) {
    this.listRawFolder = data;
  }

  @Mutation
  [Mutations.SET_LIST_PRODUCT_FOLDER](data) {
    this.listProductFolder = data;
  }

  @Mutation
  [Mutations.SET_LIST_DESIGN_CATEGORY](data) {
    this.listDesignCategory = data;
  }

  @Mutation
  [Mutations.SET_LIST_PAYMENT_METHOD](data) {
    this.listPaymentMethod = data;
  }

  @Mutation
  [Mutations.SET_LIST_WAREHOUSE](data) {
    this.listWarehouse = data;
  }

  // Actions
  @Action
  [Actions.LIST_VOUCHER]() {
    return new Promise((resolve, reject) => {
      ApiService.get("product/list_voucher_detail")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_VOUCHER, data.data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_BRAND]() {
    return new Promise((resolve, reject) => {
      ApiService.get("brand")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_BRAND, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_CATEGORY]() {
    return new Promise((resolve, reject) => {
      ApiService.get("category")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_CATEGORY, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_STORE]() {
    return new Promise((resolve, reject) => {
      ApiService.get("store")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_STORE, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_VENDOR]() {
    return new Promise((resolve, reject) => {
      ApiService.get("vendor")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_VENDOR, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_COLOR]() {
    return new Promise((resolve, reject) => {
      ApiService.get("color")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_COLOR, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_SIZE]() {
    return new Promise((resolve, reject) => {
      ApiService.get("size")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_SIZE, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_RAW_FOLDER]() {
    return new Promise((resolve, reject) => {
      ApiService.get("raw_folder")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_RAW_FOLDER, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  } 

  @Action
  [Actions.LIST_PRODUCT_FOLDER]() {
    return new Promise((resolve, reject) => {
      ApiService.get("product_folder")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_PRODUCT_FOLDER, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_DESIGN_CATEGORY](desain_id) {
    return new Promise((resolve, reject) => {
      ApiService.get("desain_kategori/"+desain_id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_DESIGN_CATEGORY, data.data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_PAYMENT_METHOD]() {
    return new Promise((resolve, reject) => {
      ApiService.get("payment_method")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_PAYMENT_METHOD, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

  @Action
  [Actions.LIST_WAREHOUSE]() {
    return new Promise((resolve, reject) => {
      ApiService.get("warehouse")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_WAREHOUSE, data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

}
