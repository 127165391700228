enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  MENU = "menu",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  ADD_RAW_BARCODE = "rawBarcode",
  ADD_FILTER = "filter",
  CREATE_RAW_DESAIN = "rawProduct",
  LIST_VOUCHER = "listVoucher",

  LIST_BRAND = "listBrand",
  LIST_CATEGORY = "listCategory",
  LIST_STORE = "listStore",
  LIST_VENDOR = "listVendor",
  LIST_COLOR = "listColor",
  LIST_SIZE = "listSize",
  LIST_RAW_FOLDER = "listRawFolder",
  LIST_PRODUCT_FOLDER = "listProductFolder",
  LIST_DESIGN_CATEGORY = "listDesignCategory",
  LIST_PAYMENT_METHOD = "listPaymentMethod",
  LIST_WAREHOUSE = "listWarehouse",

  // user
  AUTH_GROUPS = "authGroups",
  AUTH_GROUPS_MENU = "authGroupsMenu",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_MENU = "setMenu",
  SET_PASSWORD = "setPassword",
  SET_SUCCESS = "setSuccess",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  PURGE_USER_MENU = "deleteUserMenu",
  SET_RAW_BARCODE = "setRawBarcode",
  SET_FILTER = "setFilter",
  SET_RAW_DESAIN = "setRawProduct",
  SET_LIST_VOUCHER = "setListVoucher",

  SET_LIST_BRAND = "setListBrand",
  SET_LIST_CATEGORY = "setListCategory",
  SET_LIST_STORE = "setListStore",
  SET_LIST_VENDOR = "setListVendor",
  SET_LIST_COLOR = "setListColor",
  SET_LIST_SIZE = "setListSize",
  SET_LIST_RAW_FOLDER = "setListRawFolder",
  SET_LIST_PRODUCT_FOLDER = "setListProductFolder",
  SET_LIST_DESIGN_CATEGORY = "setListDesignCategory",
  SET_LIST_PAYMENT_METHOD = "setListPaymentMethod",
  SET_LIST_WAREHOUSE= "setListWarehouse",

  // user
  SET_AUTH_GROUPS = "setAuthGroups",
  SET_AUTH_MENU = "setAuthGroupsMenu",
}

export { Actions, Mutations };
