import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ComponentData {
  rawbarcode: unknown;
  filter: unknown;
}

@Module
export default class ComponentModule extends VuexModule implements ComponentData {
  rawbarcode = {};
  raw_product = {
    type: 'item',
    data: null,
  };
  get getRawBarcode() {
    return this.rawbarcode;
  }
  get getFilter() {
    return this.filter;
  }
  get getRawProduct() {
    return this.raw_product;
  }

  @Mutation
  [Mutations.SET_RAW_BARCODE](data) {
    this.rawbarcode = data;
    localStorage.setItem("raw_barcode", JSON.stringify(data))
  }

  @Mutation
  [Mutations.SET_FILTER](data) {
    //console.log(JSON.parse(JSON.stringify(data)))
    this.filter = data;
  }
  
  @Mutation
  [Mutations.SET_RAW_DESAIN](data) {
    if(typeof data.type != 'undefined') {
      this.raw_product.type = data.type;
    }
    if(typeof data.data != 'undefined') {
      this.raw_product.data = data.data;
    }
  }

  @Action
  [Actions.ADD_FILTER](data) {
    return this.context.commit(Mutations.SET_FILTER, data);
  }

  @Action
  [Actions.ADD_RAW_BARCODE](data) {
    return this.context.commit(Mutations.SET_RAW_BARCODE, data);
  }

  @Action
  [Actions.CREATE_RAW_DESAIN](data) {
    return this.context.commit(Mutations.SET_RAW_DESAIN, data);
  }

}
