import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  authGroups: unknown;
}

@Module
export default class UserModule extends VuexModule implements User {
  get getAuthGroups() {
    return this.authGroups;
  }

  @Mutation
  [Mutations.SET_AUTH_GROUPS](data) {
    this.authGroups = data;
  }

  @Action
  [Actions.AUTH_GROUPS](data) {
    return new Promise((resolve, reject) => {
      ApiService.get("user_groups")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH_GROUPS, data.data);
        resolve(data);
      })
      .catch(({ response })  => {
        reject(response);
      });
    })
  }

}
