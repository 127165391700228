import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ComponentModule from "@/store/modules/ComponentModule";
import ProductModule from "@/store/modules/ProductModule";
import UserModule from "@/store/modules/UserModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ComponentModule,
    ProductModule,
    UserModule,
  },
});

export default store;
